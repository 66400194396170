import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';

import BlockStyles from '../BlockStyles';
import documentIcon from '../../images/document.svg';
import RichTextWithDictionary from '../RichTextWithDictionary';

const Container = styled.div`
  h1 {
    font-size: 1.875rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }
`;

const ListItem = styled.div`
  margin-top: 2rem;
  text-decoration: underline;
  color: ${prop('theme.colors.redPrimary')};
  display: flex;
  align-items: center;

  img {
    margin-right: 1rem;
  }
`;

const propTypes = {
  template: PropTypes.string,
  primary: PropTypes.object,
  items: PropTypes.array
};

const defaultProps = {
  template: '',
  primary: {},
  items: []
};

function DocumentList({ template, primary, items }) {
  return (
    <>
      {items && items.length > 0 && (
        <BlockStyles template={template}>
          <Container className='block'>
            {primary.list_title.text && (
              <RichTextWithDictionary render={primary.list_title.richText} />
            )}
            {items.map((item, index) => (
              <ListItem key={`document_list_item_${index}`}>
                <img src={documentIcon} width='15' height='20' />
                <a
                  href={item.document_link.url}
                  target={item.document_link.target}
                >
                  {item.document_title.text}
                </a>
              </ListItem>
            ))}
          </Container>
        </BlockStyles>
      )}
    </>
  );
}

DocumentList.propTypes = propTypes;
DocumentList.defaultProps = defaultProps;

export default DocumentList;
